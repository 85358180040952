import React, { Suspense, SuspenseProps } from "react";

import { isSSR } from "../utils/agent";

/**
 * It's a component wrapper that replaces react Suspense with a dummy container that works in nodejs environment.
 * Webpack drops "else" condition in "node" build thanks to tree shaking.
 * Please note that current component should be deprecated as soon as react team implements nodejs support for Suspense.
 * @param props - regular Suspense properties
 */
const IsomorphicSuspense = (props: SuspenseProps) => {
    if (isSSR()) {
        return <>{props.children}</>;
    } else {
        return <Suspense {...props} />;
    }
};

export default IsomorphicSuspense;
