import isNil from "lodash/isNil";

import { isSilent, isSSR } from "../utils/agent";
import RemoteLogger from "./RemoteLogger";

enum UtagEvent {
    view = "view",
    link = "link"
}

type LogRecord = { event: UtagEvent; data: { [key: string]: unknown } };

/**
 * interval in ms
 */
const INITIALIZATION_CHECK_INTERVAL = 500;
/**
 * "give up" timeout in ms
 */
const INITIALIZATION_TIMEOUT = 5000;

export class TealiumLogger {
    /**
     * Buffer that collects logs while utag is not initialized
     * @private
     */
    private readonly logsBuffer: LogRecord[] = [];
    /**
     * "true" if initialization has succeeded
     * @private
     */
    private initialized = false;
    /**
     * "false" if initialization has FAILED and SHOULD NOT function anymore
     * @private
     */
    private active = true;
    /**
     * Instance of current singleton class
     * @private
     */
    private static instance: TealiumLogger = null;

    private timeout;
    private interval;

    constructor() {
        if (TealiumLogger.instance) {
            return TealiumLogger.instance;
        } else {
            this.initialized = false;

            const tryToInit = () => {
                if (window["utag"]) {
                    if (this.logsBuffer.length > 0) {
                        this.logsBuffer.forEach((log) => {
                            window["utag"][log.event](log.data);
                        });
                        this.logsBuffer.splice(0);
                    }

                    clearInterval(this.interval);
                    clearTimeout(this.timeout);
                    this.interval = null;
                    this.timeout = null;
                    this.initialized = true;

                    RemoteLogger.log({
                        severity: "info",
                        category: "TealiumLogger",
                        message: "Analytics initialization success"
                    });
                }
            };

            // first attempt
            tryToInit();

            // first attempt failed
            if (!this.initialized) {
                this.interval = setInterval(() => {
                    if (!this.initialized) {
                        tryToInit();
                    }
                }, INITIALIZATION_CHECK_INTERVAL);

                this.timeout = setTimeout(() => {
                    if (this.interval) {
                        clearInterval(this.interval);
                        this.interval = null;
                        this.active = false;

                        RemoteLogger.log({
                            severity: "warn",
                            category: "TealiumLogger",
                            message: "Analytics initialization failed"
                        });
                    }
                }, INITIALIZATION_TIMEOUT);
            }

            TealiumLogger.instance = this;
        }
    }

    public destroy() {
        TealiumLogger.instance = null;
        this.logsBuffer.splice(0);
        this.initialized = false;
        clearInterval(this.interval);
        clearTimeout(this.timeout);
        this.interval = null;
        this.timeout = null;
        this.active = true;
    }

    public static destroy() {
        TealiumLogger.instance?.destroy();
    }

    // wrapper for tealium view function, that store logs in a buffer array until the script is loaded
    // https://docs.tealium.com/platforms/javascript/track/#track-views
    static view(data) {
        if (!isSilent() && !isSSR() && TealiumLogger.instance.active) {
            data = {
                ...data,
                page_path: window.location.pathname,
                page_location: `${window.location.hostname}${window.location.pathname}`
            };
            if (TealiumLogger.instance.initialized) {
                window["utag"].view(data);
            } else {
                TealiumLogger.instance.logsBuffer.push({
                    event: UtagEvent.view,
                    data
                });
            }
        }
    }

    // wrapper for tealium link function (tracks events such as non-page views, page interactions, and other dynamic page events), that store logs in a buffer array until the script is loaded
    // https://docs.tealium.com/platforms/javascript/track/#track-events
    static link(data) {
        if (!isSilent() && !isSSR() && TealiumLogger.instance.active) {
            // removing fields equal to null/undefined
            Object.keys(data).forEach((k) => isNil(data[k]) && delete data[k]);
            if (TealiumLogger.instance.initialized) {
                window["utag"].link(data);
            } else {
                TealiumLogger.instance.logsBuffer.push({ event: UtagEvent.link, data });
            }
        }
    }

    /**
     * Convenience method to log a link event with supplying event name.
     */
    static linkEventName(event_name: string) {
        this.link({
            event_name
        });
    }
}
