import remove from "lodash/remove";
import SourceWithLayers from "./SourceWithLayers";

interface SourceWithLayersMap {
    [sourceID: string]: SourceWithLayers;
}

export default abstract class AbstractUserEventsHandler {
    // This is the list of all sources/layers we listen to:
    protected interactiveSourcesAndLayers: SourceWithLayersMap = {};
    protected interactiveLayerIDs: string[] = [];

    /**
     * Adds the given sources and layers as interactive, so we'll listen to them for hover and click.
     * @param sourcesWithLayers The sources and layers to listen to.
     */
    public add = (sourcesWithLayers: SourceWithLayers[]) => {
        sourcesWithLayers.forEach((sourceWithLayers) => {
            const sourceID = sourceWithLayers.source.id;
            this.interactiveSourcesAndLayers[sourceID] = sourceWithLayers;
            sourceWithLayers.layerIDs.forEach((layerID) => {
                if (!this.interactiveLayerIDs.includes(layerID)) {
                    this.interactiveLayerIDs.push(layerID);
                }
            });
        });
    };

    /**
     * Removes the given sources and layers from the interactive list. When not present, nothing happens.
     * @param sourcesWithLayers The sources and layers to remove, matched by source and layer IDs.
     */
    public remove = (sourcesWithLayers: SourceWithLayers[]) => {
        sourcesWithLayers.forEach((sourceWithLayers) => {
            delete this.interactiveSourcesAndLayers[sourceWithLayers.source.id];
            remove(this.interactiveLayerIDs, (item) => sourceWithLayers.layerIDs.includes(item));
        });
    };

    /**
     * Removes all interactive sources and layers.
     */
    public removeAll = () => {
        this.interactiveSourcesAndLayers = {};
        this.interactiveLayerIDs = [];
    };
}
