import { AdvancedLogger, service, strategy } from "advanced-logger";
import get from "lodash/get";

import { isSilent, isSSR } from "../utils/agent";

const loggerURL = "https://logs.plan.tomtom.com/log?project=ttm";

interface ILogRecord {
    message: string;
    category: string;
    severity: string;
    locationOrigin?: string;
    currentUrl?: string;
    timestamp?: number;
    data?: unknown;
    build?: string;
    session?: string;
    project?: string;
}

let loggerInstance: AdvancedLogger<ILogRecord> = null;

export default class RemoteLogger {
    static init() {
        if (!loggerInstance && !isSilent() && !isSSR()) {
            let sessionId = sessionStorage.getItem("ttm-sessionId");

            if (!sessionId) {
                const newSessionId = String(Date.now());
                sessionStorage.setItem("ttm-sessionId", newSessionId);
                sessionId = newSessionId;
            }

            /*
            Please, keep in mind this log severity map suggested by log collector:
            0: "finest"
            1: "finer"
            2: "fine"
            3: "info"
            4: "warn"
            5: "error"
            6: "fatal"
            */

            //todo Use generated SessionId unique key to track current browser session
            loggerInstance = new AdvancedLogger({
                service: new service.BaseRemoteService({
                    defaultLogConfig: {
                        session: sessionId,
                        build: APP_VERSION
                    },
                    serviceConfig: {
                        logMetaIndexField: "index",
                        method: "POST",
                        url: loggerURL,
                        retryAttempts: 5,
                        retryInterval: 5000
                    }
                }),
                strategy: new strategy.OnIntervalStrategy({
                    interval: 5000
                })
            });
        }
    }

    static log(log: ILogRecord) {
        loggerInstance &&
            loggerInstance.log({
                ...log,
                // it's easier to filter logs by required origin value
                // example: https://localhost.tomtom.com:9000/
                locationOrigin: get(window, "location.origin"),
                // example: https://localhost.tomtom.com:9000/map/poi/g6JpZK8yNTAwMDkwMDk4NTY0NzOhY6NGUkGhdqdVbmlmaWVk
                currentUrl: get(window, "location.href"),
                timestamp: Date.now()
            });
    }

    static flush() {
        loggerInstance && loggerInstance.sendAllLogs();
    }

    static generateInitialLog() {
        loggerInstance &&
            loggerInstance.log({
                message: "Application starts",
                category: "index",
                data: {
                    userAgent: get(window, "navigator.userAgent", ""),
                    viewport: {
                        screenW: get(window, "screen.width", ""),
                        screenH: get(window, "screen.height", ""),
                        pageW: get(document, "body.clientWidth", ""),
                        pageH: get(document, "body.clientHeight", ""),
                        orientation: get(window, "screen.orientation.type", ""),
                        devicePixelRatio: get(window, "devicePixelRatio", "")
                    },
                    location: {
                        origin: get(window, "location.origin"),
                        hash: get(window, "location.hash"),
                        search: get(window, "location.search")
                    }
                },
                severity: "info"
            });
    }
}
