import RemoteLogger from "./RemoteLogger";
import { isSSR } from "../utils/agent";

export default () => {
    if (!isSSR() && "serviceWorker" in navigator) {
        navigator.serviceWorker
            .register(`service-worker.${SW_VERSION}.js`)
            .then((registration) => {
                RemoteLogger.log({
                    severity: "info",
                    message: "Service Worker registration successful with scope",
                    category: "index",
                    data: {
                        scope: registration.scope
                    }
                });
            })
            .catch((error) => {
                RemoteLogger.log({
                    severity: "warn",
                    message: "Service Worker registration failed",
                    category: "index",
                    data: {
                        errormessage: error?.message ?? error
                    }
                });

                console.error(error?.message ?? error);
            });
    } else {
        RemoteLogger.log({
            severity: "warn",
            message: "Service Worker is not supported",
            category: "index"
        });
    }
};
