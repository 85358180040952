import i18next from "i18next";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { generatePath } from "react-router-dom";

import RemoteLogger from "../RemoteLogger";
import pathLanguageDetector from "./PathLanguageDetector";
import { fallbackLanguage, isLanguageSupported, supportedLanguages } from "./SupportedLanguages";
import { isBaseURLPart } from "../../components/elements/Links";
/* eslint-disable */
// See https://stackoverflow.com/questions/7035825/regular-expression-for-a-language-tag-as-defined-by-bcp47/60899733#60899733
const grandFathered =
    "(" +
    /* irregular */ "(en-GB-oed|i-ami|i-bnn|i-default|i-enochian|i-hak|i-klingon|i-lux|i-mingo|i-navajo|i-pwn|i-tao|i-tay|i-tsu|sgn-BE-FR|sgn-BE-NL|sgn-CH-DE)" +
    "|" +
    /* regular */ "(art-lojban|cel-gaulish|no-bok|no-nyn|zh-guoyu|zh-hakka|zh-min|zh-min-nan|zh-xiang)" +
    ")";
const langTag =
    "(" +
    "(" +
    ("([A-Za-z]{2,3}(-" + "([A-Za-z]{3}(-[A-Za-z]{3}){0,2})" + ")?)|[A-Za-z]{4}|[A-Za-z]{5,8})") +
    "(-" +
    "([A-Za-z]{4})" +
    ")?" +
    "(-" +
    "([A-Za-z]{2}|[0-9]{3})" +
    ")?" +
    "(-" +
    "([A-Za-z0-9]{5,8}|[0-9][A-Za-z0-9]{3})" +
    ")*" +
    "(-" +
    "(" +
    /* singleton */ ("[0-9A-WY-Za-wy-z]" + "(-[A-Za-z0-9]{2,8})+)") +
    ")*" +
    "(-" +
    "(x(-[A-Za-z0-9]{1,8})+)" +
    ")?" +
    ")";
export const languageTagRegexp = new RegExp(`^(${grandFathered}|${langTag}|(x(-[A-Za-z0-9]{1,8})+))$`);
// for api calls to TomTom services that require locale, we need to map our language to corresponding locale
export const languageToAPILocale = {
    en: "en-GB",
    es: "es-ES",
    nl: "nl-NL",
    de: "de-DE",
    fr: "fr-FR",
    it: "it-IT",
    pt: "pt-PT",
    sv: "sv-SE",
    cs: "cs-CZ",
    da: "da-DK",
    el: "el-GR",
    fi: "fi-FI",
    hr: "hr-HR",
    hu: "hu-HU",
    no: "no-NO",
    pl: "pl-PL",
    ru: "ru-RU",
    sl: "sl-SL",
    tr: "tr-TR",
    zh: "zh-CN"
};
// for return to MDW
// for mapping to respective account create locale
// for mapping to respective password reset locale
// for mapping to respective locale for legal & support links
export const supportedLocale = {
    en: "en_gb",
    es: "es_es",
    nl: "nl_nl",
    de: "de_de",
    fr: "fr_fr",
    it: "it_it",
    pt: "pt_pt",
    sv: "sv_se",
    cs: "cs_cz",
    da: "da_dk",
    el: "el_gr",
    fi: "fi_fi",
    hr: "hr_hr",
    hu: "hu_hu",
    no: "no_no",
    pl: "pl_pl",
    ru: "ru_ru",
    sl: "sl_sl",
    tr: "tr_tr",
    zh: "zh_cn"
};

export const isLanguage = (name: string) => languageTagRegexp.test(name);

export const initializeLocalization = async () => {
    const languageDetector = new LanguageDetector(null, {
        order: ["lookupFromPath", "localStorage"]
    });

    languageDetector.addDetector(pathLanguageDetector);

    const httpApi = new HttpApi(null, {
        loadPath: `locales/{{lng}}/{{ns}}-${APP_VERSION}.json`
    });

    i18next.on("failedLoading", (language, namespace, message) => {
        RemoteLogger.log({
            message: "Localization loading failed",
            category: "Localization",
            severity: "warn",
            data: { language, namespace, message }
        });
    });

    i18next.on("missingKey", (languages: readonly string[], namespace: string, key: string, res: string) => {
        RemoteLogger.log({
            message: "Localization key is missing",
            category: "Localization",
            severity: "warn",
            data: { languages, namespace, key, res }
        });
    });

    i18next.on("languageChanged", (language) => {
        RemoteLogger.log({
            message: "Language was changed",
            category: "Localization",
            severity: "info",
            data: { language }
        });
    });

    try {
        await i18next
            .use(httpApi)
            .use(initReactI18next)
            .use(languageDetector)
            .init({
                fallbackLng: fallbackLanguage,
                ns: ["common"],
                supportedLngs: supportedLanguages,
                defaultNS: "common",
                debug: false,
                react: {
                    useSuspense: true
                },
                interpolation: { escapeValue: false } //because react already escapes
            });
    } catch (error) {
        RemoteLogger.log({
            message: "Localization initialization failed",
            category: "Localization",
            severity: "error",
            data: {
                errormessage: error?.message ?? error
            }
        });
        console.error(error?.message ?? error);
    }
};

export const isBaseURLPathMatch = (actualPath: string, expectedPath: string) => {
    const language = i18next.language;

    if (!language || !actualPath || !expectedPath) {
        throw new Error(
            `Localization.isBaseURLPathMatch: wrong parameters ${JSON.stringify({
                language: language || "",
                actualPath: actualPath || "",
                expectedPath: expectedPath || ""
            })}`
        );
    }

    return actualPath.startsWith(generatePath(expectedPath, { language: i18next.language }));
};

export const isExactURLPathMatch = (actualPath: string, expectedPath: string) => {
    const language = i18next.language;

    if (!language || !actualPath || !expectedPath) {
        throw new Error(
            `Localization.isExactURLPathMatch: wrong parameters ${JSON.stringify({
                language: language || "",
                actualPath: actualPath || "",
                expectedPath: expectedPath || ""
            })}`
        );
    }

    const toCompare = actualPath.endsWith("/") ? actualPath.substring(0, actualPath.length - 1) : actualPath;
    return toCompare === generatePath(expectedPath, { language });
};

export const isURLPathLocalised = (urlPath: string) => {
    if (typeof urlPath !== "string") throw new Error("Localization.isURLPathLocalised: wrong parameters");

    const parts = urlPath.split("/");
    if (parts.length <= 1) {
        return false;
    }

    const maybeLanguage = parts[1];

    if (isBaseURLPart(maybeLanguage)) {
        return false;
    }

    return isLanguage(maybeLanguage);
};

export const isURLPathLanguageSupported = (urlPath: string) => {
    if (!isURLPathLocalised(urlPath))
        throw new Error(`Localization.isURLPathLanguageSupported: the route "${urlPath}" has no language!`);

    const language = urlPath.split("/")[1];

    return isLanguageSupported(language);
};
