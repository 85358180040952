import { GeoJSONSource, GeoJSONSourceSpecification } from "maplibre-gl";
import { Feature, FeatureCollection } from "geojson";
import identity from "lodash/identity";
import { featureCollection } from "@turf/helpers";

import MapSource from "./MapSource";

class GeoJsonSource<F extends Feature = Feature, T = Feature> extends MapSource<GeoJSONSource> {
    constructor(
        id: string,
        transform?: (from: T, index: number, array: T[]) => F,
        options: Omit<GeoJSONSourceSpecification, "type"> = { data: featureCollection([]) }
    ) {
        const data = (options.data as FeatureCollection) || featureCollection([]);
        super(id, {
            type: "geojson",
            data,
            // generating IDs always by default:
            generateId: true,
            ...options
        });
        this.transform = transform || identity;
        this.renderedFeatures = data;
    }

    transform: (from: T, index: number, array: T[]) => F;
    // Last rendered input features after calling one of the reconfigureXXX methods:
    renderedFeatures: FeatureCollection<F["geometry"], F["properties"]>;
}

export default GeoJsonSource;
