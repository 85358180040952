import MapSource from "./MapSource";
import { LayerConfig } from "./LayerTypes";
import MapProvider from "./MapProvider";

export default class SourceWithLayers<S extends MapSource = MapSource> {
    source: S;
    layerConfigs: LayerConfig[];
    layerIDs: string[];
    visible = true;

    constructor(source: S, layers: LayerConfig | LayerConfig[]) {
        this.source = source;
        layers = Array.isArray(layers) ? layers : [layers];
        this.layerConfigs = layers.map((layer) => ({
            beforeId: layer.beforeId,
            layer: { ...layer.layer, source: source.id }
        }));
        this.layerIDs = this.layerConfigs.map((layerConfig) => layerConfig.layer.id);
    }

    public ensureAddedToMap = () => {
        const map = MapProvider.map;
        this.source.ensureAddedToMap();
        this.layerConfigs.forEach((layerConfig) => {
            if (!map.getLayer(layerConfig.layer.id)) {
                map.addLayer(layerConfig.layer, layerConfig.beforeId);
            }
        });
    };

    public ensureAddedToMapWithVisibility = (visible: boolean, options?: { onlyIfSourceAlreadyExisting?: boolean }) => {
        const map = MapProvider.map;
        if (!options?.onlyIfSourceAlreadyExisting || map.getSource(this.source.id)) {
            this.ensureAddedToMap();
            this.setVisible(visible);
        }
    };

    public setVisible = (visible: boolean) => {
        const map = MapProvider.map;
        this.layerIDs.forEach((layerID) => map.setLayoutProperty(layerID, "visibility", visible ? "visible" : "none"));
        this.visible = visible;
    };
}
