export const fallbackLanguage = "en";
// ISO 639-1
export const supportedLanguages = [
    "cs",
    "da",
    "de",
    "el",
    fallbackLanguage,
    "es",
    "fi",
    "fr",
    "hr",
    "hu",
    "it",
    "nl",
    "no",
    "pl",
    "pt",
    "ru",
    "sl",
    "sv",
    "tr",
    "zh"
];

export const isLanguageSupported = (language: string) => supportedLanguages.includes(language);
