import React from "react";
import { Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Empty } from "../../ui-library";

import { isURLPathLocalised, isURLPathLanguageSupported } from "./Localization";
import { Redirection, URL_PATH_MAP } from "../../components/elements/Links";

export const RedirectToLocalizedURLPath = () => {
    const { i18n } = useTranslation();

    return (
        <Route path="*">
            {({ location }) => {
                if (!isURLPathLocalised(location.pathname)) {
                    // if path is not localized -> redirect
                    // /route/plan -> /en/route/plan
                    return <Redirection pathname={`/${i18n.language}${location.pathname}` as URL_PATH_MAP} />;
                } else if (!isURLPathLanguageSupported(location.pathname)) {
                    // if path is localized, but language is not supported -> redirect
                    // /i-klingon/route/plan -> /nl/route/plan
                    // /en-US/route/plan -> /nl/route/plan (not "en", but a previous detected supported language)
                    return (
                        <Redirection
                            pathname={
                                `/${i18n.language}${location.pathname.replace(/^\/[A-Za-z0-9-]+/, "")}` as URL_PATH_MAP
                            }
                        />
                    );
                } else {
                    // language is present and supported
                    // /en/route/plan
                    return <Empty />;
                }
            }}
        </Route>
    );
};
