import { Source, SourceSpecification } from "maplibre-gl";

import MapProvider from "./MapProvider";

export default class MapSource<S extends Source = Source> {
    public readonly id: string;
    public readonly initData: SourceSpecification;
    // Runtime source object once it's loaded on the map:
    public source: S;

    constructor(id: string, initData: SourceSpecification) {
        this.id = id;
        this.initData = initData;
    }

    public ensureAddedToMap = () => {
        const map = MapProvider.map;
        if (!map.getSource(this.id)) {
            map.addSource(this.id, this.initData);
        }
        this.source = map.getSource(this.id) as S;
    };
}
