// See https://stackoverflow.com/questions/7035825/regular-expression-for-a-language-tag-as-defined-by-bcp47

import { isBaseURLPart } from "../../components/elements/Links";
import { isLanguageSupported } from "./SupportedLanguages";
import { isLanguage } from "./Localization";

export default {
    name: "lookupFromPath",

    lookup() {
        const maybeLanguage = window.location.pathname.split("/")?.[1];

        if (isBaseURLPart(maybeLanguage)) {
            return undefined;
        }

        if (!isLanguage(maybeLanguage)) {
            return undefined;
        }

        if (!isLanguageSupported(maybeLanguage)) {
            return undefined;
        }

        // It is our supported localised pathname \o/
        return maybeLanguage;
    }
};
