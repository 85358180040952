import GeoJsonSource from "./GeoJsonSource";
import SourceWithLayers from "./SourceWithLayers";
import { Feature, FeatureCollection } from "geojson";
import { featureCollection } from "@turf/helpers";
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";

export class GeoJsonSourceWithLayers<F extends Feature = Feature, T = Feature> extends SourceWithLayers<
    GeoJsonSource<F, T>
> {
    public reconfigureData = (data?: FeatureCollection<F["geometry"], F["properties"]>) => {
        data = data || this.source.renderedFeatures;
        if (!isEmpty(data?.features) && isEmpty(this.source.renderedFeatures?.features)) {
            this.setVisible(true);
        } else if (isEmpty(data?.features) && !isEmpty(this.source.renderedFeatures?.features)) {
            this.setVisible(false);
        }
        this.source.source.setData(data);
        this.source.renderedFeatures = data;
    };

    public reconfigureFeatures = (features: F | F[]) => {
        features = Array.isArray(features) ? features : [features];
        this.reconfigureData(featureCollection(compact(features)));
    };

    public reconfigure = (values: T | T[]) => {
        values = Array.isArray(values) ? values : [values];
        this.reconfigureFeatures(values.map(this.source.transform));
    };

    public isEmptyOrHidden = () => !this.visible || isEmpty(this.source.renderedFeatures?.features);
}
