import { MapGeoJSONFeature, LngLat } from "maplibre-gl";

import AbstractUserEventsHandler from "./AbstractUserEventsHandler";
import SourceWithLayers from "./SourceWithLayers";
import MapProvider from "./MapProvider";

export type DragEventType = "DRAG_BEGIN" | "DRAG_CONTINUE" | "DRAG_END";

export interface DragHandler {
    (
        draggedLngLat: LngLat,
        draggingFeature: MapGeoJSONFeature,
        draggedSourceWithLayers: SourceWithLayers,
        dragType: DragEventType
    ): void;
}

export default class DragEventsHandler extends AbstractUserEventsHandler {
    private mouseDownFromRoute = false;
    private delayedDragStartHandleID: number;
    private draggingIntervalHandleID: number;
    private previousIntervalDraggedCoordinates: LngLat;
    private lastDraggedCoordinates: LngLat;
    private draggingFeature: MapGeoJSONFeature;
    private draggingSourceWithLayers: SourceWithLayers;

    private dragCallback: DragHandler;

    public listenToDragEvents = (dragCallback: DragHandler) => {
        this.dragCallback = dragCallback;
        this.listenToMouseDown();
        this.listenToMouseMove();
        this.listenToMouseUp();
    };

    private listenToMouseDown = () => {
        const map = MapProvider.map;
        map.on("mousedown", (ev) => {
            // (we ignore drags while the map moves to ignore accidental route drags while panning)
            if (!map.isMoving()) {
                const draggedFeatures = map.queryRenderedFeatures(ev.point, { layers: this.interactiveLayerIDs });
                if (draggedFeatures?.length) {
                    this.draggingFeature = draggedFeatures[0];
                    this.draggingSourceWithLayers = this.interactiveSourcesAndLayers[draggedFeatures[0].source];
                    map.dragPan.disable();
                    // We delay the enabling of dragging to ignore accidental 'draggy' clicks:
                    this.delayedDragStartHandleID = window.setTimeout(() => (this.mouseDownFromRoute = true), 300);
                }
            }
        });
    };

    private listenToMouseMove = () => {
        const map = MapProvider.map;
        map.on("mousemove", (ev) => {
            if (this.mouseDownFromRoute) {
                this.lastDraggedCoordinates = ev.lngLat;
                if (!this.draggingIntervalHandleID) {
                    this.dragCallback(
                        this.lastDraggedCoordinates,
                        this.draggingFeature,
                        this.draggingSourceWithLayers,
                        "DRAG_BEGIN"
                    );
                    this.draggingIntervalHandleID = window.setInterval(() => {
                        if (
                            this.lastDraggedCoordinates?.lat !== this.previousIntervalDraggedCoordinates?.lat ||
                            this.lastDraggedCoordinates?.lng !== this.previousIntervalDraggedCoordinates?.lng
                        ) {
                            this.dragCallback(
                                this.lastDraggedCoordinates,
                                this.draggingFeature,
                                this.draggingSourceWithLayers,
                                "DRAG_CONTINUE"
                            );
                        }
                        this.previousIntervalDraggedCoordinates = this.lastDraggedCoordinates;
                    }, 300);
                }
            }
        });
    };

    private listenToMouseUp = () => {
        const map = MapProvider.map;
        map.on("mouseup", () => {
            if (this.delayedDragStartHandleID) {
                window.clearInterval(this.delayedDragStartHandleID);
                this.delayedDragStartHandleID = null;
                map.dragPan.enable();
                this.mouseDownFromRoute = false;
                window.clearInterval(this.draggingIntervalHandleID);
                this.draggingIntervalHandleID = null;
                if (this.lastDraggedCoordinates) {
                    this.dragCallback(
                        this.lastDraggedCoordinates,
                        this.draggingFeature,
                        this.draggingSourceWithLayers,
                        "DRAG_END"
                    );
                }
                this.lastDraggedCoordinates = null;
                this.draggingFeature = null;
                this.draggingSourceWithLayers = null;
            }
        });
    };
}
