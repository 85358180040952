import { Feature, Point } from "geojson";
import { TTMWaypoint } from "../../utils/locationTypes";
import { waypointToFeature } from "../../utils/waypoint";
import { GeoJsonSourceWithLayers } from "./GeoJsonSourceWithLayers";

/**
 * Override of GeoJsonSource which allows for extra needed context to generate waypoint GeoJSON features.
 */
class WaypointGeoJsonSourceWithLayers extends GeoJsonSourceWithLayers<Feature<Point>, TTMWaypoint> {
    reconfigure = (waypoints: TTMWaypoint[], waypointToPlannerInputIndexes?: number[]) => {
        this.reconfigureFeatures(
            waypoints.map((waypoint, index, array) =>
                waypointToFeature(waypoint, index, array, waypointToPlannerInputIndexes)
            )
        );
    };
}

export default WaypointGeoJsonSourceWithLayers;
