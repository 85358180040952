import mapLibreExported, { Map, MapOptions, PointLike } from "maplibre-gl";

const centeringOffset: PointLike = [90, 0];
const centeringOffsetMobile: PointLike = [0, 75];
const centeringOffsetWithTimeline: PointLike = [90, -50];
const centeringOptions = {
    padding: 200,
    offset: centeringOffset
};
const centeringOptionsWithTimeline = {
    padding: 200,
    offset: centeringOffsetWithTimeline
};
const centeringOptionsMobile = {
    padding: 50,
    offset: centeringOffsetMobile
};

const isMobileScreen = (): boolean => window.innerWidth < 600;

export default class MapProvider {
    private static isMapInitTriggered = false;
    static _map: Map;

    static get map() {
        return MapProvider._map;
    }

    static initializeMap(options: MapOptions) {
        if (!MapProvider._map) {
            MapProvider._map = new Map(options);

            if (mapLibreExported.getRTLTextPluginStatus() === "unavailable") {
                mapLibreExported.setRTLTextPlugin(
                    "https://unpkg.com/@mapbox/mapbox-gl-rtl-text@0.2.3/mapbox-gl-rtl-text.min.js",
                    (err) => console.log("MapProvider.initializeMap setRTLTextPlugin err", err),
                    true
                );
            }
        }

        return MapProvider._map;
    }

    static getCenteringOptions = (routeTimeline?: boolean) => {
        if (isMobileScreen()) {
            return centeringOptionsMobile;
        }
        return routeTimeline ? centeringOptionsWithTimeline : centeringOptions;
    };

    static getCenteringOffset = (routeTimeline?: boolean): PointLike => {
        if (isMobileScreen()) {
            return centeringOffsetMobile;
        }
        return routeTimeline ? centeringOffsetWithTimeline : centeringOffset;
    };
}
