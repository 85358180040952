import React, { PropsWithChildren } from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import RemoteLogger from "./RemoteLogger";

type ErrorHandlerState = {
    errorMessage: string;
    errorStackSample: string;
    errorOccurred: boolean;
};

type Props = PropsWithChildren<WithTranslation>;

class GlobalErrorHandler extends React.Component<Props, ErrorHandlerState> {
    constructor(props) {
        super(props);
        this.state = { errorOccurred: false, errorMessage: "", errorStackSample: "" };
    }

    componentDidCatch(error, info) {
        const errorMessage = error?.message ?? error;
        const errorStackSample = (error?.stack ?? info.componentStack).split("\n").slice(0, 20).join("\n") ?? "";

        this.setState({
            errorOccurred: true,
            errorMessage,
            errorStackSample
        });

        RemoteLogger.log({
            severity: "error",
            message: "Uncaught error",
            category: "GlobalErrorHandler",
            data: {
                // js error message
                errorMessage,
                // js error info
                errorStackSample,
                // react error info
                info
            }
        });
    }

    render() {
        const { t } = this.props;

        if (this.state.errorOccurred) {
            return (
                <>
                    <h1>{t("common:error.global.oopstitle")}</h1>
                    <h2>{this.state.errorMessage}</h2>
                    <pre>{this.state.errorStackSample}</pre>
                </>
            );
        } else {
            return this.props.children;
        }
    }
}

export default withTranslation("common")(GlobalErrorHandler);
